import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const Seo = ({
  description,
  lang = "de",
  meta = [],
  keywords = [],
  title,
  children,
}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            titleTemplate
            description
            author
            url
            image
          }
        }
      }
    `}
    render={(data) => {
      description = description || data.site.siteMetadata.description;
      title = title || data.site.siteMetadata.title;
      return (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={data.site.siteMetadata.titleTemplate}
          meta={[
            {
              name: `description`,
              content: description,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: description,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: `${data.site.siteMetadata.url}${data.site.siteMetadata.image}`,
            },
            {
              property: `og:image:width`,
              content: 2160,
            },
            {
              property: `og:image:height`,
              content: 1131,
            },
            {
              name: `twitter:card`,
              content: `summary_large_image`,
            },
            {
              name: `twitter:creator`,
              content: data.site.siteMetadata.author,
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: description,
            },
          ]
            .concat([
              {
                name: "keywords",
                content: keywords.join(", "),
              },
            ])
            .concat(meta)}
        >
          {children}
        </Helmet>
      );
    }}
  />
);

export default Seo;
